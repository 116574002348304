import { useTranslation } from 'next-i18next';
import React from 'react';
import { useLocations } from '@web/shop-logic';
import { Transfer } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import FormatTime from '@/components/atoms/FormatTime';
import Map from '@/components/connection/Map';
import TransferTime from '@/components/connection/TransferTime';

interface TransferModalProps {
  info: string;
  transfer: Transfer;
  arrivalTime: string;
  departureTime: string;
}

const TransferModal: React.FC<TransferModalProps> = ({
  transfer,
  departureTime,
  arrivalTime,
  info,
}) => {
  const { t } = useTranslation();
  const { getStation } = useLocations();

  const arrivalStation = getStation(transfer.fromStationId);
  const departureStation = getStation(transfer.toStationId);

  return (
    <div className="flex sm:flex-col">
      <div className="flex flex-col lg:w-1/2">
        <div className="p-1 border border-neutral-gray-3 rounded-sm">
          <div className="flex font-bold">
            <div>
              <FormatTime date={arrivalTime} />
            </div>
            <div className="pl-1">{arrivalStation.fullname}</div>
          </div>
          <TransferTime transfer={transfer} />
          <div className="flex font-bold">
            <div>
              <FormatTime date={departureTime} />
            </div>
            <div className="pl-1">{departureStation.fullname}</div>
          </div>
        </div>
        <p className="mt-2 text-14 text-justify">{info}</p>
        <p className="mt-2 text-14 text-justify">{transfer.description}</p>
        <img className="mt-2" alt={t('connections.station')} src={departureStation.imageUrl} />
      </div>
      <Map
        className="lg:w-1/2 ml-1"
        shouldShowPedestrianDirections
        points={[arrivalStation, departureStation]}
      />
    </div>
  );
};

export default TransferModal;
