import React from 'react';
import { SeatClass } from '@web/shop-logic/dist/hooks/types';
import { SeatSection } from '@web/shop-logic/dist/hooks/useSeats';
import SeatingCardInner from '@/components/seating/SeatingCardInner';
import { useSeatSelection } from '@/hooks/useSeatSelection';

interface ReservedSeatingCardProps {
  routeSection: SeatSection;
  seatingClass?: SeatClass;
}

const ReservedSeatingCard: React.FC<ReservedSeatingCardProps> = ({
  routeSection,
  seatingClass,
}) => {
  const { vehicleNr, selectedDeckNr, setSelectedDeckNr, setVehicleNr } =
    useSeatSelection(routeSection);
  return (
    <SeatingCardInner
      classInfo={{ seatClassKey: seatingClass }}
      onSeatSelect={() => undefined}
      openedFromTicket
      seatSection={routeSection}
      selectedSeats={routeSection.selectedSeats}
      setVehicleNr={setVehicleNr}
      vehicleNr={vehicleNr}
      selectedDeckNr={selectedDeckNr}
      setSelectedDeckNr={setSelectedDeckNr}
    />
  );
};

export default ReservedSeatingCard;
