import React from 'react';
import LineSeatClasses from '@/components/connection/LineSeatClasses';
import { SeatClassCount } from '@/components/connection/SectionDetail';

interface SeatingDetailProps {
  priceClasses: SeatClassCount[];
}

const SeatingDetail: React.FC<SeatingDetailProps> = ({ priceClasses }) => (
  <div className="flex flex-wrap sm:flex-col lg:items-center gap-x-1">
    {/* if Bus connection there is just one obj which has property seatClassKey: "NO"  */}
    {priceClasses && priceClasses?.[0]?.seatClassKey !== 'NO' && (
      <LineSeatClasses priceClasses={priceClasses} />
    )}
  </div>
);
export default SeatingDetail;
