import { Trans } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { SeatClass } from '@web/shop-logic/dist/hooks/types';
import { PriceClass, Section } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import { SeatSection } from '@web/shop-logic/dist/hooks/useSeats';
import Badge, { SupportConnectionBadge } from '@/components/atoms/Badge';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import ConnectionServiceBadge from '@/components/atoms/ConnectionServiceBadge';
import VehicleTypeIcon from '@/components/atoms/VehicleTypeIcon';
import Line from '@/components/connection/Line';
import SeatingDetail from '@/components/connection/SeatingDetail';
import SeatClassName from '@/components/fare/SeatClassName';
import useModal from '@/components/modal/useModal';
import ReservedSeatingCard from '@/components/seating/ReservedSeatingCard';
import SeatWagonBadges from '@/components/seating/SeatWagonBadges';
import { LocationOn, Circle } from '@/icons';
import { ShopFaresImageEntity } from '@/models/types';
import { dayDiff, formatConnectionTime } from '@/utils/dateTimeUtils';

export type SeatClassCount = Pick<PriceClass, 'seatClassKey' | 'freeSeatsCount'>;

export interface SectionDetailProps {
  isFirst: boolean;
  isLast: boolean;
  isPopover?: boolean;
  priceClasses?: SeatClassCount[];
  faresImagesData?: ShopFaresImageEntity[];
  routeSection?: SeatSection;
  section: Section;
  setClosedPopper?: () => void;
  classKey?: SeatClass;
  firstDepartureTime: string;
}

const iconClasses = 'w-2.5 h-2.5 lg:w-3 lg:h-3 fill-primary-blue';

const SectionDetail: React.FC<SectionDetailProps> = ({
  classKey,
  isFirst,
  isLast,
  isPopover,
  priceClasses,
  faresImagesData,
  firstDepartureTime,
  routeSection,
  setClosedPopper,
  section: {
    arrivalCityName,
    arrivalStationName,
    arrivalTime,
    arrivalStationId,
    delay,
    departureCityName,
    departurePlatform,
    departureStationName,
    departureStationId,
    departureTime,
    freeSeatsCount,
    id,
    line,
    support,
    travelTime,
    vehicleStandardKey,
    vehicleType,
    services,
  },
}) => {
  const { showModal } = useModal();
  const serviceType =
    (services.includes('typy_vozidel_stevardka') && 'steward') ||
    (services.includes('typy_vozidel_teple_napoje_zdarma') && 'coffee') ||
    null;

  const openSeatingDetail = () => {
    if (setClosedPopper) setClosedPopper();
    showModal(
      <ReservedSeatingCard key={id} routeSection={routeSection} seatingClass={classKey} />,
      {
        size: 'big',
      },
    );
  };

  const dayDifferenceArrival = dayDiff(firstDepartureTime, arrivalTime);
  const dayDifferenceDeparture = dayDiff(firstDepartureTime, departureTime);

  return (
    <div
      className={classNames(
        'cardOpenTransferContainer',
        isFirst && 'cardOpenTransferContainer-first',
        isPopover && 'cardOpenTransferContainer-pop-over',
      )}
    >
      <div className={classNames('col-span-3 font-bold sm:text-14', !isPopover && 'hidden')}>
        {formatConnectionTime(departureTime, 'SHORT_DATE')}
        {!!dayDifferenceDeparture && <sup>+{dayDifferenceDeparture}</sup>}
      </div>
      <div className="cardOpenTransfer font-bold sm:text-14 self-center" aria-hidden>
        {formatConnectionTime(departureTime, 'HOURS_MINUTES')}
        {!!dayDifferenceDeparture && <sup>+{dayDifferenceDeparture}</sup>}
      </div>
      <div className="cardOpenTransfer cardOpenTransfer-depart-icon">
        <VehicleTypeIcon className={iconClasses} vehicleType={vehicleType} />
      </div>
      <div className="cardOpenTransfer self-center font-bold sm:text-14" aria-hidden>
        <span className="pr-0.5">
          {departureCityName}, {departureStationName}
        </span>
        {departurePlatform && (
          <span className="cardOpenTransferContainer-platform inline-flex">
            <Trans i18nKey="connections.transferInfoModal.platform" /> {departurePlatform}
          </span>
        )}
      </div>
      <div className="cardOpenTransfer text-13 lg:text-14 text-neutral-gray" aria-hidden>
        {travelTime}
      </div>
      <div className="cardOpenTransfer-line" />
      <div className="cardOpenTransfer cardOpenTransfer-details">
        <div className="cardOpenTransfer-details-children flex flex-col gap-1 items-start">
          <Line
            fromStationId={departureStationId}
            lineProps={line}
            sectionId={id}
            toStationId={arrivalStationId}
            vehicleStandardKey={vehicleStandardKey}
            vehicleType={vehicleType}
            faresImagesData={faresImagesData}
          />
          {support && <SupportConnectionBadge clearTooltip={vehicleStandardKey !== 'ECONOMY'} />}
          {freeSeatsCount !== null && (
            <div className="flex sm:flex-col lg:items-center">
              <SeatingDetail priceClasses={priceClasses} />
            </div>
          )}
          {/* Ybus vrací code jako array ve kterém bude vždy jen 1 code nebo null */}
          {routeSection?.vehicles?.[0]?.code && (
            <div className="text-neutral-gray text-14 sm:text-13">
              <Trans i18nKey="bus.code" /> {routeSection.vehicles[0].code}
            </div>
          )}
          {!!serviceType && <ConnectionServiceBadge type={serviceType} />}
          {!!routeSection?.selectedSeats && (
            <ClickableDiv onClick={openSeatingDetail}>
              <SeatWagonBadges
                className="flex gap-1"
                removeWagon={vehicleType === 'BUS'}
                selectedSeats={routeSection.selectedSeats}
              />
            </ClickableDiv>
          )}
          {classKey && (
            <div className="text-14 text-neutral-gray">
              <Trans i18nKey="passenger.tariff" />:{' '}
              <SeatClassName
                seatClass={classKey}
                type="RJ_SEAT"
                standard={vehicleType === 'BUS' ? vehicleStandardKey : undefined}
              />
            </div>
          )}
        </div>
        {delay && (
          <Badge variant="informational">
            <Trans i18nKey="delays.title" /> {delay}
          </Badge>
        )}
      </div>
      <div className="cardOpenTransfer self-center font-bold sm:text-14" aria-hidden>
        {formatConnectionTime(arrivalTime, 'HOURS_MINUTES')}
        {!!dayDifferenceArrival && <sup>+{dayDifferenceArrival}</sup>}
      </div>
      <div className="cardOpenTransfer cardOpenTransfer-arrival-icon">
        {isLast ? <LocationOn className={iconClasses} /> : <Circle className={iconClasses} />}
      </div>
      <div className="cardOpenTransfer font-bold sm:text-14" aria-hidden>
        {arrivalCityName}, {arrivalStationName}
      </div>
    </div>
  );
};

export default SectionDetail;
