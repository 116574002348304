import { Trans } from 'next-i18next';
import React from 'react';
import { FlexiType, TicketType } from '@web/shop-logic/dist/hooks/types';
import Badge from '@/components/atoms/Badge';

interface TicketHeaderBadgeProps {
  type: TicketType;
  flexiType?: FlexiType;
}
const TicketHeaderBadge: React.FC<TicketHeaderBadgeProps> = ({ type, flexiType = undefined }) => (
  <Badge className="text-black bg-transparent border-black items-center">
    {type === 'RJ_SRO' && <Trans i18nKey="ticket.types.SRO" />}
    {type === 'RJ_TIME' && !flexiType && <Trans i18nKey="ticket.types.TIME" />}
    {flexiType && <Trans i18nKey={`ticket.flexiTypes.${flexiType}`} />}
  </Badge>
);

export default TicketHeaderBadge;
