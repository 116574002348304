import { Trans } from 'next-i18next';
import React from 'react';
import { Warning } from '@/icons';

const TrafficInterruption: React.FC = () => (
  <div className="flex items-center gap-0.5 text-13 lg:text-14 text-secondary-redwarn">
    <Warning className="w-2.5 h-2.5 fill-current" />
    <div className="sm:hidden" aria-hidden>
      <Trans i18nKey="connection.interruption" />
    </div>
    <div className="sr-only">
      <Trans i18nKey="connection.interruption" />
    </div>
  </div>
);

export default TrafficInterruption;
