import { Trans } from 'next-i18next';
import React from 'react';
import { Stevardess, LocalCafe, IconElement } from '@/icons';

type Service = 'steward' | 'coffee';

const mapIconToService: Record<Service, IconElement> = {
  steward: Stevardess,
  coffee: LocalCafe,
};

interface ServiceIconProps {
  type: Service;
}

const ConnectionServiceBadge: React.FC<ServiceIconProps> = ({ type }) => {
  const Icon = mapIconToService[type];

  return (
    <div className="inline-flex items-center text-neutral-gray mb-0.5 text-13 lg:text-14">
      <Icon className="w-3 h-3 mr-1 fill-neutral-gray2" />
      <span className="sr-only">
        <Trans i18nKey={`accessibility.connection.${type}`} />
      </span>
      <span aria-hidden>
        <Trans i18nKey={`icons.popover.${type}`} />
      </span>
    </div>
  );
};

export default ConnectionServiceBadge;
