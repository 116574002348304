import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { Transfer } from '@web/shop-logic/dist/hooks/useConnectionRoute';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import TransferModal from '@/components/connection/TransferModal';
import useModal from '@/components/modal/useModal';
import { Time } from '@/icons';

interface TransferCalculatedTimeProps {
  arrivalTime?: string;
  departureTime?: string;
  info?: string;
  transfer: Transfer;
  isPopover?: boolean;
}

const TransferTime: React.FC<TransferCalculatedTimeProps> = ({
  arrivalTime,
  departureTime,
  info,
  transfer,
  isPopover = false,
}) => {
  const { t } = useTranslation();
  const { showModal } = useModal();

  const { calculatedTransferTime } = transfer;

  return (
    <div className={classNames('transfer-detail', isPopover && 'transfer-detail-pop-over')}>
      <div className="transfer-detail-item1" />
      <div className="transfer-detail-item2" />
      <div className="transfer-detail-calculated-time text-13 lg:ml-1 lg:text-14 text-neutral-gray">
        <Trans
          i18nKey="connections.info.transferWaiting"
          values={{
            timePeriodHours: calculatedTransferTime.hours,
            /**
             * pokud calculatedTransferTimes.minutes < 10
             * přidáváme 0 kvůli správnému formátování textu
             */
            timePeriodMinutes: calculatedTransferTime.minutes.toString().padStart(2, '0'),
          }}
        />
        {arrivalTime && departureTime && info && (
          <ClickableDiv
            className="ml-2 text-13 lg:text-14 hover:underline text-primary-blue cursor-pointer"
            onClick={() =>
              showModal(
                <TransferModal
                  key={`${transfer.toStationId}-${transfer.fromStationId}`}
                  info={info}
                  transfer={transfer}
                  arrivalTime={arrivalTime}
                  departureTime={departureTime}
                />,
                {
                  size: 'big',
                  title: t('connections.transferInfoModal.header'),
                  unmountOnClose: true,
                },
              )
            }
          >
            <Trans i18nKey="connections.info.transferInfo" />
          </ClickableDiv>
        )}
      </div>
      <div className="transfer-detail-item4">
        <Time />
      </div>
      <div className="transfer-detail-item5" />
    </div>
  );
};

export default TransferTime;
