import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import classNames from 'classnames';
import { SeatClassCount } from '@/components/connection/SectionDetail';

interface LineSeatClassesProps {
  priceClasses: SeatClassCount[];
}

const LineSeatClasses: React.FC<LineSeatClassesProps> = ({ priceClasses }) => {
  const { t } = useTranslation();
  /**
   * Pokud má spoj možnost zakoupení flexi jízdenky, posílají se některá volná sedadla opakovaně
   * Z objektu vyhodíme duplicitní hodnoty pro stejnou třídu
   */
  const filteredPriceClasses = [
    ...new Map(priceClasses.map((item) => [item.seatClassKey, item])).values(),
  ].filter((pc) => pc.freeSeatsCount);

  return (
    <ul
      aria-label={t('accessibility.free.seats')}
      className="flex flex-wrap sm:flex-col lg:items-center gap-x-1"
    >
      {filteredPriceClasses.map(({ seatClassKey, freeSeatsCount }) => (
        <li
          key={seatClassKey}
          className={classNames(
            'text-13 lg:text-14',
            freeSeatsCount >= 10 && 'text-secondary-green',
            !!freeSeatsCount && freeSeatsCount < 10 && 'text-secondary-orange',
            !freeSeatsCount && 'text-secondary-redwarn',
          )}
        >
          <Trans i18nKey={`SeatClassInfo.${seatClassKey}`} />
          :&nbsp;{freeSeatsCount}
        </li>
      ))}
    </ul>
  );
};

export default LineSeatClasses;
